<template>
  <div id="app">
    <!-- <loadDetails /> -->
    <router-view />

  </div>
</template>


<script>
import loadDetails from "@/components/loadDetails.vue";
import axios from "axios";
export default {
  components: {
    loadDetails
  },
  }
</script>
<style>
/* 所有的el-input框 被禁用时 的样式 文字为黑色 */
::v-deep .el-input.is-disabled .el-input__inner {
    color: #000;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  padding: 0;
  margin: 0;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.bgimg{
  background: #eef2f5;
  //background-image: url("assets/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.white{
    color: white;
}
</style>
